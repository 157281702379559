// @flow
import React, { useState, useEffect, useCallback, useRef } from "react"
import { navigate } from "gatsby"
import { Auth } from "aws-amplify"
import isEqual from "lodash/isEqual"

import type { UserType } from "@lesmills/gatsby-theme-common"

import type { SubscriptionType } from "../../types/SubscriptionType"

import type {
  CreateAccountPageType,
  UpdatePaymentPageType,
} from "../../types/CreateAccountPageType"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import {
  Button,
  setLocalStorage,
  getLocalStorage,
  NEW_SUBSCRIPTION_KEY,
  formatPrismicCountries,
  USER_COUNTRY,
  USER_POSTAL_CODE,
  getCurrentUser,
  isEmpty,
  handleErrorWithPrismic,
  NotificationToast,
  isBrowser,
  getCreateAccountRoute,
  captureException,
  SENTRY_ACTIONS,
  COUNTRY_CODES,
  USER_INFO_KEY,
  checkTimeAfterUpdatedPayment,
  updateUserCart,
  getSessionStorage,
  CURRENT_COUNTRY,
  MEMBERSHIP_INFO,
  updateMembershipInfo,
  GetUser,
  checkHaveErrors,
  ROLLOVER_PREFERENCE,
  setSessionStorage,
  RECAPTCHA_TOKEN,
  getAutomationRecaptcha,
  handleClickOutSideRecaptcha,
  Spinner,
} from "@lesmills/gatsby-theme-common"

import CreateAccountContainer from "./CreateAccountContainer"
import { withLookupLocationHOC } from "../../utils/LookupLocationHOC"
import {
  updateChargifyPaymentUtil,
  updateUserAddress,
} from "../../utils/UpdateChargifyPaymentUtil"
import {
  getSecurityChargifyToken,
  isDisablePayPalCountries,
  isBraintreeUser,
} from "../../utils/payment"

import {
  handleBeforeSubmitManualForm,
  getCurrentCountry,
  formalizePlaceDetails,
} from "../../utils/LookupLocationUtil"
import { PROCESSING_VERIFY_CARD } from "../../constants/chargify-type"
import { getPaymentInfo, enabled3DSBillingAddress } from "../../utils/payment"
import loadable from "@loadable/component"

const ReCAPTCHA = loadable(() => import("react-google-recaptcha"), {
  fallback: <Spinner />,
})
const ChargifyDropIn = loadable(() => import("../ChargifyDropIn"), {
  fallback: <Spinner />,
})
const RolloverPreferences = loadable(() => import("./RolloverPreferences"), {
  fallback: <Spinner />,
})
const BillingInformation = loadable(() => import("../BillingInformation"), {
  fallback: <Spinner />,
})

type Props = {|
  isExistingAccount?: Boolean,
  data: CreateAccountPageType,
  paymentData: UpdatePaymentPageType,
  layoutData: AllLayoutsType,
  isProcessing: Boolean,
  setIsProcessing: () => void | Promise<void>,
  handleLookupLocation: () => void | Promise<void>,
  handleUpdateGoBackMsg: () => void,
  onMouseDownSubmit: () => void,
  lookupErr: String,
  lookupResult: Object,
  navigateLocation: Object,
  lang: string,
  handlePushStateToHistory: () => void,
  selectedSubscription?: SubscriptionType,
  isAffiliate?: Boolean,
  user?: UserType,
  accordionRef: () => void | Promise<void>,
  isExpanded: Boolean,
  handleOnFocus: () => void | Promise<void>,
  handleOnChange: () => void | Promise<void>,
  handleSelectPlace: () => void | Promise<void>,
  fullAddressRef: () => void | Promise<void>,
  placeDetails: Object,
  onSwitchManualForm?: () => void | Promise<void>,
  isShowManualForm?: boolean,
  manualRef: Object, // contains all refs for form fields
  states?: Array,
  fullAddress?: String,
  defaultZipCode?: String,
  isAllowRollover: boolean,
  rolloverOption: String,
  setRolloverOption: () => void | Promise<void>,
  isCanceledAccount?: Boolean,
  isShowSelectedSubBanner?: Boolean,
  setIsShowSelectedSubBanner: () => void | Promise<void>,
  isNeedValidateStateZipCode?: Boolean,
  isStateRequired?: Boolean,
  setPlaceDetails?: () => void,
|}

let currentUserLocation, _isExpandedCreditCard, _chargifyInfoData

const Payment = ({
  isExistingAccount = false,
  data,
  paymentData,
  layoutData = {},
  isProcessing = false,
  setIsProcessing = () => {},
  handleLookupLocation = () => {},
  onMouseDownSubmit = () => {},
  lookupErr = "",
  lookupResult = {},
  navigateLocation,
  handleUpdateGoBackMsg = () => {},
  handlePushStateToHistory = () => {},
  lang,
  selectedSubscription = {},
  isAffiliate,
  isCanceledAccount,
  user = {},
  accordionRef = () => {},
  isExpanded,
  handleOnFocus = () => {},
  fullAddressRef = () => {},
  handleOnChange = () => {},
  handleSelectPlace = () => {},
  placeDetails = {},
  manualRef = () => {},
  onSwitchManualForm = () => {},
  isShowManualForm = false,
  states = [],
  fullAddress = "",
  defaultZipCode = "",
  isAllowRollover = false,
  rolloverOption = "",
  setRolloverOption = () => {},
  isShowSelectedSubBanner,
  setIsShowSelectedSubBanner,
  isNeedValidateStateZipCode,
  isStateRequired,
  setPlaceDetails = () => {},
}: Props) => {
  const [errors, setErrors] = useState({
    payment: "",
    account: "",
    rollover: "",
  })
  const { executeRecaptcha } = useGoogleReCaptcha()
  const state = navigateLocation.state || {}

  const [userInfo, setUserInfo] = useState({
    country: "",
    postalCode: "",
  })
  const [securityChargifyInfo, setSecurityChargifyInfo] = useState()
  const [isLoading, setIsLoading] = useState()
  const [chargifyInfoData, setChargifyInfo] = useState(null)
  const [isChangedPayment, setIsChangedPayment] = useState(false)
  const [isExpandedCreditCard, setIsExpandedCreditCard] = useState(false)
  const [isShowForceUpdatePayment, showForceUpdatePayment] = useState(false)
  const [tokenV3, setTokenV3] = useState(null) // Token for signIn

  const recaptchaAutomation = getAutomationRecaptcha()
  const currentCountry = getSessionStorage(CURRENT_COUNTRY)

  const currentSubscription = getLocalStorage(NEW_SUBSCRIPTION_KEY)
  const {
    payment_continue_button_label = {},
    payment_loading_button_label = {},
    payment_subtitle = {},
    payment_title = {},
    payment_existing_account_title = {},
    payment_existing_account_subtitle = {},
    no_payment_method_error = {},
    user_not_found_exception = {},
    payment_returning_account_subtitle = {},
    selected_button = {},
    rollover_preference_required = {},
    restart_when_moving_country = {},
  } = data || {}

  const {
    addressCountry,
    addressLocality,
    addressPostalCode,
    addressRegion,
    addressStateCode,
    addressStreet,
    addressStreet2,
  } = user

  // https://lesmillsinternational.atlassian.net/browse/LA-1060
  // When user selects a subs at /membership, addressCountry and billing address is reset
  const isDifferentCountry =
    addressCountry &&
    !addressLocality &&
    !addressPostalCode &&
    !addressRegion &&
    !addressStateCode &&
    !addressStreet &&
    !addressStreet2
  const isRestartWhenMovingCountry = isCanceledAccount && isDifferentCountry
  const [restartWhenMovingCountry, setRestartWhenMovingCountry] = useState(
    isRestartWhenMovingCountry ? restart_when_moving_country.text : ""
  )
  const { body = [], saving_payment_error = {}, full_address_invalid = {} } =
    paymentData || {}

  const { require_update_payment_error = {} } = layoutData

  const countries =
    body[0] && body[0].items ? formatPrismicCountries(body[0].items) : []
  const currentUserInfo = getLocalStorage(USER_INFO_KEY)

  const currentPaymentMethod = isRestartWhenMovingCountry
    ? null
    : getPaymentInfo(currentUserInfo)

  let reCaptchaRef = useRef()

  const onClickOutsideRecaptcha = () => {
    setIsProcessing(false)
  }

  const scrollToTop = () => {
    isBrowser && window.scrollTo(0, 0)
  }

  const handlePickRolloverOption = value => {
    setRolloverOption(value)
    setErrors({
      ...errors,
      rollover: "",
    })
  }

  const handleCardErrors = (err, action, sentryError, isChargify) => {
    if (sentryError === undefined) {
      sentryError = err
    }

    if (err.type !== PROCESSING_VERIFY_CARD) {
      const error = {
        payment: err.message,
      }

      if (isChargify) {
        handleValidateForm(error)
      } else {
        // Handle error
        setErrors(error)
        setIsProcessing(false)
      }
    }

    scrollToTop()
    // Send error to sentry
    if (action) {
      captureException({
        action,
        ...sentryError,
      })
    }
  }

  const updateChargifyPayment = async chargifyInfo => {
    await updateChargifyPaymentUtil({
      isStateRequired,
      chargifyToken: chargifyInfo.chargifyToken,
      err: errors.payment,
      userLocation: currentUserLocation,
      userInfo,
      countries,
      states,
      defaultZipCode,
      onCardErrorFn: cardError => {
        handleCardErrors(cardError, SENTRY_ACTIONS.THREE_D_SECURE)
        return
      },
      onResponsePaymentError: err => {
        handleErrorWithPrismic(
          err,
          saving_payment_error.text,
          handleCardErrors,
          data,
          SENTRY_ACTIONS.UPDATE_PAYMENT_CHARGIFY // This action will be sent to sentry in production mode
        )
      },
      callBackAfterUpdatingPaymentFn: updatedUserInfo => {
        setLocalStorage(ROLLOVER_PREFERENCE, rolloverOption)

        // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1048
        // Keep indicator until navigate
        // Only setIsProcessing(false) when there are any error
        navigate(`${getCreateAccountRoute(isAffiliate, lang)}?step=summary`, {
          state: {
            paymentMethod:
              updatedUserInfo.chargifyPaymentProfile ||
              updatedUserInfo.paymentMethod,
          },
        })
      },
      reCaptchaV2Ref: reCaptchaRef,
      setCountryError: () => {
        setIsProcessing(false)
        setErrors({ payment: full_address_invalid.text })
      },
    })
  }

  const handleAfterValidation = (callback = () => {}) => {
    if (handleValidateForm()) {
      callback()
    }
  }

  const handleChargifySuccess = async chargifyInfo => {
    // Reset error when verify payment success
    setErrors({
      ...errors,
      payment: null,
    })
    // CreditCard token get by handleSubmit button so it continues updateChargifyPayment
    // Paypal token get by Paypal button so it must click handleSubmit to updateChargifyPayment
    if (chargifyInfo && chargifyInfo.paymentType === "CreditCard") {
      handleAfterValidation(() => {
        updateChargifyPayment(chargifyInfo)
      })

      return
    }

    setChargifyInfo(chargifyInfo)
  }

  const checkLocation = async (callback = () => {}) => {
    if (isShowManualForm) {
      currentUserLocation = await handleBeforeSubmitManualForm(
        manualRef,
        placeDetails,
        handleLookupLocation,
        handleOnChange,
        states,
        countries,
        defaultZipCode,
        isNeedValidateStateZipCode
      )
    } else {
      currentUserLocation = await handleLookupLocation()
    }

    // LME-1007: wait for finish validate address to call callback function
    if (currentUserLocation) {
      callback()
    } else {
      setIsProcessing(false)
    }
  }

  const handleValidateForm = (currentErrors = {}) => {
    if (isAllowRollover && !rolloverOption) {
      currentErrors.rollover = rollover_preference_required.text
    }

    setErrors({
      ...currentErrors,
    })

    // Switch manual form if info remained is not matched with all validation rules on the Billing Address
    if (fullAddress && fullAddress !== placeDetails.result) {
      onSwitchManualForm()
      setIsProcessing(false)
      return false
    }

    if (checkHaveErrors(currentErrors) || !currentUserLocation) {
      setIsProcessing(false)
      return false
    }

    const { countryCode, postalCode } = currentUserLocation
    // Save to localstorage to use next time access
    setLocalStorage(USER_COUNTRY, countryCode)
    setLocalStorage(USER_POSTAL_CODE, postalCode)
    return true
  }

  const handleNavigateSummary = () => {
    setLocalStorage(ROLLOVER_PREFERENCE, rolloverOption)
    navigate(`${getCreateAccountRoute(isAffiliate, lang)}?step=summary`, {
      state: {
        paymentMethod: currentPaymentMethod,
      },
    })
  }

  const verifyRecaptchaV3 = useCallback(
    async (callback = () => {}) => {
      if (!executeRecaptcha) {
        return
      }
      const paymentTokenV3 = await executeRecaptcha()
      setSessionStorage(RECAPTCHA_TOKEN, paymentTokenV3)
      callback()
    },
    [executeRecaptcha]
  )

  const handleUpdateForm = async () => {
    const newBillingAddress = formalizePlaceDetails(
      currentUserLocation,
      countries,
      states,
      isStateRequired
    )
    const currentUserData = getLocalStorage(USER_INFO_KEY)
    const isChangedAddress = !isEqual(
      newBillingAddress,
      formalizePlaceDetails(currentUserData, countries, states, isStateRequired)
    )

    // Always verify card when open credit cart
    if (isChangedPayment) {
      if (recaptchaAutomation) {
        handleUpdatePayment()
      } else {
        // Generate reCaptcha Token V3 for payment
        verifyRecaptchaV3(handleUpdatePayment)
        handleClickOutSideRecaptcha(onClickOutsideRecaptcha)
      }
    } else if (currentPaymentMethod && isChangedAddress) {
      handleAfterValidation(() =>
        updateUserAddress(
          newBillingAddress,
          currentUserData,
          defaultZipCode,
          states,
          isStateRequired,
          err => {
            err = err[0] && err[0].errorType
            setErrors({
              payment: layoutData[err].text,
            })
            setIsProcessing(false)
          },
          () => {
            setIsProcessing(false)
            handleNavigateSummary()
          },
          () => setErrors({ payment: full_address_invalid.text })
        )
      )
      // back to step 2 but no changes more
    } else if (currentPaymentMethod) {
      handleAfterValidation(() => handleNavigateSummary())
    } else {
      // no input payment and address
      handleValidateForm({
        payment: no_payment_method_error.text,
      })
      setIsProcessing(false)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    setErrors({})

    setIsProcessing(true)
    checkLocation(handleUpdateForm)
  }

  const handleUpdatePayment = () => {
    if (_isExpandedCreditCard) {
      document.getElementById("submit-credit-card").click()
    } else if (
      _chargifyInfoData &&
      _chargifyInfoData.paymentType === "PayPalAccount"
    ) {
      handleAfterValidation(() => updateChargifyPayment(_chargifyInfoData))
    }
  }

  const handleSetUser = (res = {}) => {
    const {
      email,
      addressStateCode,
      addressRegion,
      addressLocality,
      addressCountry,
    } = res
    handleUpdateGoBackMsg(email)
    const localStorageData = getLocalStorage(USER_INFO_KEY)

    // If user was updated after 5s, we can get data directly from user
    // Otherwise, we will get from localStorage
    const currentUser = checkTimeAfterUpdatedPayment(localStorageData, 5)
      ? res
      : localStorageData

    const country = getLocalStorage(USER_COUNTRY) // Country can be picked from Membership
    const region = addressStateCode || addressRegion || addressLocality
    if (addressCountry && typeof region === "string") {
      setPlaceDetails(
        formalizePlaceDetails(res, countries, states, isStateRequired)
      )
    }

    // Save country and postal code to bind into billing form
    setUserInfo({
      ...res,
      city: currentUser.addressLocality,
      state: currentUser.addressRegion,
      country:
        currentUser.addressCountry ||
        (!isEmpty(country) ? country : COUNTRY_CODES.Germany),
      postalCode: currentUser.addressPostalCode,
    })

    // Only save country and postal code at the first time, to avoid overriding
    if (!isEmpty(country)) {
      return
    }

    // Save to localstorage to use next time access
    setLocalStorage(
      USER_COUNTRY,
      currentUser.addressCountry || COUNTRY_CODES.Germany
    )
    setLocalStorage(USER_POSTAL_CODE, currentUser.addressPostalCode || "")
  }

  const handleError = err => {
    setErrors({
      account: err.message,
    })
    scrollToTop()
  }

  const handleAutoSignIn = async (
    reCaptchaToken = null,
    isRecaptchaV2 = false
  ) => {
    let signInRequestParams = {
      username: state.email.toLowerCase(),
      password: state.password,
    }

    const validationDataName = isRecaptchaV2
      ? "recaptchaToken"
      : "recaptchaTokenV3"

    // Automation already be checked at step 1
    // With automation test, value is "" otherwise it is recaptchaToken
    if (state.recaptchaAutomation) {
      signInRequestParams.validationData = {
        Name: validationDataName,
        Value: "",
        auth: state.recaptchaAutomation.auth,
        nonce: state.recaptchaAutomation.nonce,
      }
    } else {
      signInRequestParams.validationData = {
        Name: validationDataName,
        Value: reCaptchaToken,
      }
    }

    await Auth.signIn(signInRequestParams)
      .then(() => {
        const product = getLocalStorage(NEW_SUBSCRIPTION_KEY)
        const membershipInfo = getLocalStorage(MEMBERSHIP_INFO)
        // update user cart information after finish step 1
        // https://lesmillsinternational.atlassian.net/browse/LA-1069
        // flag is true means need to add addressCountry to check different country if user doesn't input billing address
        // AB2B-521: Reset product info if user select signedLink
        if (product.signedLink) {
          updateUserCart(
            { product_handle: null },
            false,
            () => {},
            err =>
              setErrors({
                ...errors,
                payment: layoutData[err.errorType].text,
              })
          )
        } else {
          updateUserCart(
            product,
            true,
            () => {},
            err =>
              setErrors({
                ...errors,
                payment: layoutData[err.errorType].text,
              })
          )
        }

        // https://lesmillsinternational.atlassian.net/browse/LA-982
        // should update membership info if selected a lmnz offer
        if (product.isNZOffers && !isEmpty(membershipInfo)) {
          updateMembershipInfo(
            membershipInfo,
            () => {},
            err =>
              setErrors({
                account: data[err].text,
              })
          )
        }

        // get current user to binding email, user
        getCurrentUser(
          handleSetUser,
          handleError,
          user_not_found_exception.text,
          undefined,
          undefined,
          GetUser
        )
        // get Security Chargify Token after get current user
        if (!securityChargifyInfo) {
          getSecurityChargifyToken(
            setIsLoading,
            setErrors,
            setSecurityChargifyInfo
          )
        }
      })
      .catch(async err => {
        if (
          err &&
          err.message &&
          err.message.toLowerCase().includes("failed recaptcha v3 verification")
        ) {
          const tokenV2 = await reCaptchaRef.current.executeAsync()
          reCaptchaRef.current.reset()
          handleAutoSignIn(tokenV2, true)
        } else {
          // Get error from Prismic
          setErrors({
            account: data[err.code] ? data[err.code].text : "",
          })
          scrollToTop()
          // Send error to sentry
          if (err.code !== "UserNotFoundException") {
            captureException({
              action: SENTRY_ACTIONS.SIGN_IN,
              requestVariables: {
                username: signInRequestParams.username,
                validationData: signInRequestParams.validationData,
              },
              ...err,
            })
          }
          return
        }
      })
  }

  useEffect(() => {
    _isExpandedCreditCard = isExpandedCreditCard
    _chargifyInfoData = chargifyInfoData
  }, [isExpandedCreditCard, chargifyInfoData])

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    const recaptchaTokenV3 = await executeRecaptcha("paymentStep")
    setTokenV3(recaptchaTokenV3)
  }, [executeRecaptcha])

  useEffect(() => {
    // Sign in and get country, postal code
    const initialisePaymentPage = async () => {
      if (!tokenV3) {
        handleReCaptchaVerify()
      }
      handlePushStateToHistory()

      // Prop user has been passed from PublicRoute
      // If user already exists => setUser
      // If comming from step 1 => need to login and getUser
      if (!isEmpty(user)) {
        handleSetUser(user)
        if (!securityChargifyInfo) {
          getSecurityChargifyToken(
            setIsLoading,
            setErrors,
            setSecurityChargifyInfo,
            isRestartWhenMovingCountry,
            currentCountry
          )
        }
        // Require force update payment if user only have braintree payment info
        if (isBraintreeUser(user)) {
          setErrors({ payment: require_update_payment_error.text })
          showForceUpdatePayment(true)
          if (isChangedPayment && isShowForceUpdatePayment) {
            showForceUpdatePayment(false)
          }
        }
      } else if (state.email && !!tokenV3) {
        // Update go back msg
        handleUpdateGoBackMsg(state.email)
        handleAutoSignIn(tokenV3, false)
      }
    }

    initialisePaymentPage()
  }, [tokenV3])

  return (
    <CreateAccountContainer
      title={
        isExistingAccount
          ? payment_existing_account_title.text
          : payment_title.text
      }
      subtitle={
        isCanceledAccount
          ? payment_returning_account_subtitle
          : isExistingAccount
          ? payment_existing_account_subtitle
          : payment_subtitle
      }
      testId="add-payment-form"
      classNames={{ wrapper: "animation-face-in-right" }}
    >
      {errors && errors.account && (
        <NotificationToast
          children={<p>{errors.account}</p>}
          showHideIcon
          handleHideToast={() => setErrors({ ...errors, account: "" })}
          type="error"
          classNames={{
            wrapper: " mt-20",
          }}
        />
      )}
      {restartWhenMovingCountry ? (
        <NotificationToast
          children={<p>{restartWhenMovingCountry}</p>}
          showHideIcon
          handleHideToast={() => setRestartWhenMovingCountry("")}
          type="error"
          classNames={{
            wrapper: " mt-20",
          }}
        />
      ) : null}
      {errors && errors.payment && (
        <NotificationToast
          children={<p>{errors.payment}</p>}
          showHideIcon
          handleHideToast={() => setErrors({ ...errors, payment: "" })}
          type="error"
          classNames={{
            wrapper: " mt-20",
          }}
        />
      )}
      {isShowSelectedSubBanner && (
        <NotificationToast
          children={
            <p>
              {selectedSubscription.name} {selected_button.text}
            </p>
          }
          showHideIcon
          handleHideToast={() => setIsShowSelectedSubBanner(false)}
          type="success"
          classNames={{
            wrapper: " mt-20",
          }}
        />
      )}
      {securityChargifyInfo && (
        <ChargifyDropIn
          prismicData={layoutData}
          securityChargifyInfo={securityChargifyInfo}
          onGetChargifySuccess={handleChargifySuccess}
          handleCardErrors={(err, action, sentryError) => {
            handleCardErrors(err, action, sentryError, true)
          }}
          isLoading={isLoading}
          currentPaymentUser={currentPaymentMethod}
          setIsChangedPayment={setIsChangedPayment}
          isEnabled3DS={enabled3DSBillingAddress(currentCountry)}
          setIsExpandedCreditCard={setIsExpandedCreditCard}
          isDisabledPayPal={isDisablePayPalCountries()}
        />
      )}
      <div className="w-full">
        <BillingInformation
          currentCountry={getCurrentCountry(
            placeDetails,
            getSessionStorage(CURRENT_COUNTRY)
          )}
          countries={countries}
          data={paymentData}
          inline={false}
          error={lookupErr}
          lookupResult={lookupResult}
          accordionRef={accordionRef}
          isExpanded={isExpanded}
          onFocus={handleOnFocus}
          fullAddressRef={fullAddressRef}
          handleOnChange={handleOnChange}
          handleSelectPlace={handleSelectPlace}
          placeDetails={placeDetails}
          manualRef={manualRef}
          onSwitchManualForm={onSwitchManualForm}
          isShowManualForm={isShowManualForm}
          states={states}
          defaultZipCode={defaultZipCode}
          isStateRequired={isStateRequired}
        />
        {isAllowRollover && (
          <RolloverPreferences
            currentSubscription={currentSubscription}
            selectedSubscription={selectedSubscription}
            layoutData={layoutData}
            prismicData={data}
            onChange={handlePickRolloverOption}
            rolloverOption={rolloverOption}
            error={errors.rollover}
            classNames={{
              wrapper:
                "border-gray-35 border-b pt-32 pb-32 mb-26 border-t text-left",
              buttonWrapper: " mt-25 mb-15",
              button: " leading-22 text-base",
              message: " text-base leading-22",
              question: " text-base leading-22",
            }}
          />
        )}
      </div>
      {/* Button disable in these cases:
       - Processing
       - User is Braintree user
         and haven't open CC form
         and haven't update successful CC or PP on Chargify
      */}
      {!recaptchaAutomation && (
        <ReCAPTCHA
          ref={reCaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_SITE_KEY}
        />
      )}
      <Button
        handleOnClick={handleSubmit}
        className="btn btn-primary pt-3 pb-3 w-full md:mb-45 mb-35 mt-8"
        disabled={
          isProcessing ||
          (isShowForceUpdatePayment &&
            !isExpandedCreditCard &&
            !chargifyInfoData)
        }
        submitting={isProcessing}
        onMouseDown={onMouseDownSubmit}
        testId="review-order-button"
        innerRef={manualRef.buttonSubmitRef}
      >
        {isProcessing
          ? payment_loading_button_label.text
          : payment_continue_button_label.text}
      </Button>
    </CreateAccountContainer>
  )
}

export default withLookupLocationHOC(Payment, true)
